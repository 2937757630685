<template>
  <CodePage
    v-bind:can-resend="false"
    v-on:codeSubmit="handleCode"
  >
    <template #title>
      {{ $t('auth---Verification code') }}
    </template>

    <template #note>
      {{ $t('auth---Enter code received via Email {email}', { email: maskedEmail }) }}
    </template>

    <template #submit>
      {{ $t('auth---Enter') }}
    </template>
  </CodePage>
</template>

<i18n src="../i18n/translations/codePage.json"></i18n>

<script>
import CodePage from '../components/CodePage.vue';

export default {
  data() {
    return {
      maskedEmail: this.$store.state.maskedEmail
    };
  },
  components: {
    CodePage,
  },
  methods: {
    handleCode(code) {
      this.$store.dispatch('apiLogin', code);
    },
  }
};
</script>
